<template>
  <div class="no-padding">
    <div style="height: 100%;">
      <h4>{{ $t('ADVANCED_SEARCH.EDUCATIONS.HEADER') }}</h4>
      <!-- Add membership begin -->

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('ADVANCED_SEARCH.EDUCATIONS.SEARCH_EDUCATION')">

              <b-form-input
                v-model="text"
              >
              </b-form-input>


            </b-form-group>
          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>
            <div class="justify-content-end d-flex">
              <b-button class="mr-2 cancel" variant="danger" @click="cancel_clicked" >{{$t('COMMON.CANCEL')}}</b-button>
              <b-button ref="save" class="save" variant="primary" type="submit">{{$t('COMMON.SELECT')}}</b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

export default {

  name: 'EducationsSelectEditor',

  props: ['educations'],
  emits: ['selected', 'cancel'],

  mixins: [ toasts ],

  components: {

  },

  watch: {

  },

  computed: {
    ...mapGetters(['currentCompanyId']),
    education_options() {
      return this.educations.map((item) => ({ text: item.code.toUpperCase(), value: item.id }));
    },

  },


  mounted() {
    if (this.educations && this.educations.length > 0) {
      this.education_id = this.educations[0].id;
    }

  },

  methods: {

    cancel_clicked() {
      this.$emit('cancel');
    },


    async on_submit() {

      this.education_id = this.education_options.find(item => item.text === this.text.toUpperCase()).value;

      this.$emit('selected', this.education_id);
    },
  },


  data() {
    return {

      text: null,
      education_id: null,

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
