<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b px-0">
    <!--begin::Header-->
    <div class="card-header border-0">
      <div class="card-toolbar d-flex justify-content-between justify-content-sm-end">
        <div class="d-flex align-items-center mr-sm-12 my-2">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="show_history" @click="show_history = !show_history" />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer font-size-sm">{{$t('PAGES.SENDOUT.SHOW_HISTORY')}}</span>
        </div>

        <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click.prevent="createSendoutClicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.SENDOUT.NEW_SENDOUT')}}</a>

      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 table-responsive">
      <b-table
        id="template-table"
        :fields="headers"
        :items="items"
        head-variant="light"
        sticky-header
        class="table-striped"
      >
        <template #cell(name)="row">
          <h6><b-badge>{{ row.item.is_sms ? 'SMS' : $t('MEMBER.EMAIL') }}</b-badge></h6>{{ row.item.name }}
        </template>
        <template #cell(template_id)="row">

          <div class='justify-content-end d-flex'>
            <a
              v-if="row.item.status !== 'DRAFT' && row.item.batch_id"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click.prevent="download_report(row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">

                <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>

              </span>
            </a>

            <a v-if="row.item.template_id !== null && row.item.template_id !== ''"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click.prevent="previewSendout(row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/vial-solid.svg"></inline-svg>
              </span>
            </a>

            <a v-if="row.item.status === 'DRAFT' && row.item.template_id !== null &&
              row.item.template_id !== ''"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="startSendout(row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/paper-plane.svg"></inline-svg>
              </span>
            </a>
            <a v-if="row.item.status === 'DRAFT'"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="selectSendoutClicked(row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="deleteSendoutClicked(row.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import axios from 'axios';

import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'sendouts-table',

  mixins: [ toasts ],

  props: ['items', 'currentCompanyId'],
  emits: ['createSendoutClicked', 'selectSendoutClicked', 'deleteSendoutClicked', 'previewSendout', 'startSendout', 'showHistoryToggled'],
  components: {

  },
  watch: {
    show_history(newcheck, oldcheck) {
      this.$emit('showHistoryToggled', newcheck);
    }
  },
  methods: {
    async download_report(sendout) {

      try {
        const res = await axios.post(`/email/bounce-report/${sendout.batch_id}`);

        if (res.status === 201) {
          const file = res.data;

          downloadWithAxios(get_base_url() + `/dlfile/${file.linkstr}`, file.name);
          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.ERROR_DOWNLOADING_FILE'));

    },
    previewSendout(sendout) {
      this.$emit('previewSendout', sendout);
    },
    startSendout(sendout) {
      this.$emit('startSendout', sendout);
    },
    createSendoutClicked() {
      this.$emit('createSendoutClicked');
    },
    selectSendoutClicked(sendout) {
      this.$emit('selectSendoutClicked', sendout.sendout_id);
    },
    deleteSendoutClicked(sendout) {
      this.$emit('deleteSendoutClicked', sendout);
    },
  },
  data() {
    return {
      show_history: false,
      list: [],
      headers: [
        {
          key: 'name',
          label: this.$t('PAGES.SENDOUT.SENDOUT'),
          formatter: (_, __, item) => {
            return item.name.substring(0, 25);
          },
          sortable: true,
          thClass: 'w-165px align-middle',
          tdClass: 'w-165px align-middle',
        },
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          thClass: 'w-165px align-middle',
          tdClass: 'w-165px align-middle',
          sortable: true
        },
        {
          key: 'num_to_send',
          label: '# ' + this.$t('COMMON.SELECTION'),
          thClass: 'w-110px align-middle',
          tdClass: 'align-middle',
          sortable: true
        },
        {
          key: 'num_email_sent',
          label: '# ' + this.$t('SENDOUT.NUM_OK'),
          thClass: 'w-110px align-middle',
          tdClass: 'align-middle',
          sortable: true,
          formatter: (_, __, item) => {

            if (item.is_sms) {
              return item.num_ok;
            }

            return item.batch && item.batch.stats ? item.batch.stats.Sent : '-';
          },
        },
        {
          key: 'num_fail',
          label: '# ' + this.$t('SENDOUT.NUM_FAIL'),
          thClass: 'w-110px align-middle',
          tdClass: 'align-middle',
          sortable: true,
          formatter: (_, __, item) => {
            if (item.is_sms) {
              return item.num_fail;
            }

            return item.batch && item.batch.stats ? item.batch.stats.UniqueLinksClicked : '-';
          },
        },
        {
          key: 'num_opens',
          label: '# ' + this.$t('PAGES.SENDOUT.OPENS'),
          thClass: 'w-110px align-middle',
          tdClass: 'align-middle',
          sortable: true,
          formatter: (_, __, item) => {
            if (item.is_sms) {
              return this.$t('SENDOUT.NOT_AVAILABLE');
            }

            return item.batch && item.batch.stats ? item.batch.stats.UniqueOpens : '-';
          },
        },

        {
          key: 'num_bounce',
          label: '# ' + this.$t('PAGES.SENDOUT.BOUNCES'),
          thClass: 'w-110px align-middle',
          tdClass: 'align-middle',
          sortable: true,
          formatter: (_, __, item) => {
            if (item.is_sms) {
              return this.$t('SENDOUT.NOT_AVAILABLE');
            }

            return item.batch && item.batch.stats ? item.batch.stats.Bounced : '-';
          },
        },
        {
          key: 'template_id',
          label: '',
          thClass: 'w-110px text-right pr-6',
          tdClass: 'align-middle'
        },
      ]
    };
  }
};
</script>


<style lang="css" scoped>
.b-table-sticky-header {
  overflow-y: hidden;
  max-height: unset;
}
</style>
