<template>

  <div ref="page-container" class="page-container">

    <b-row>
      <b-col lg="3" sm="12" v-if="settings.is_faith">
        <!-- SKV Period selection -->
        <b-form-group
          :label="$t('PERIOD.SKV_PERIOD_INTAG')">
          <b-form-select
            v-model="local_search_options.member_variables.skv_period_id"
            :options="period_options">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="3" sm="12" v-if="!settings.is_faith">
        <!-- Period selection -->
        <b-form-group
          :label="$t('PERIOD.PERIOD')">
          <b-form-select
            v-model="local_search_options.member_variables.period_id"
            :options="period_options">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="3" sm="12">
        <!-- Register date -->
        <b-form-group
          :label="$t('ADVANCED_SEARCH.RDT')">
          <b-row>
            <b-col cols="6">
              <memlist-date-picker
                v-model="local_search_options.member_variables.rdt_from"
                :placeholder="$t('ADVANCED_SEARCH.RDT_FROM')"
                style="margin-top: 8px; max-width: 98%;"
              >
              </memlist-date-picker>
            </b-col>
            <b-col cols="6">
              <memlist-date-picker
                v-model="local_search_options.member_variables.rdt_to"
                :placeholder="$t('ADVANCED_SEARCH.RDT_TO')"
                style="margin-top: 8px; max-width: 98%;"
              >
              </memlist-date-picker>
            </b-col>
          </b-row>

        </b-form-group>
      </b-col>
      <b-col lg="3" sm="12">
        <!-- Age interval -->
        <b-form-group
          :label="$t('ADVANCED_SEARCH.AGE')">
          <b-row>
            <b-col cols="6">
              <b-form-input style="margin-left: -4px; margin-top: 8px;" :placeholder="$t('ADVANCED_SEARCH.AGE_FROM')" v-model="local_search_options.member_variables.age_from" />
            </b-col>
            <b-col cols="6">
              <b-form-input style="margin-top: 8px;" :placeholder="$t('ADVANCED_SEARCH.AGE_TO')" v-model="local_search_options.member_variables.age_to" />
            </b-col>
          </b-row>

        </b-form-group>
      </b-col>

      <b-col lg="3" sm="12">
        <!-- Company IDs selection -->
        <b-form-group
          :label="$t('ADVANCED_SEARCH.COMPANIES')">

          <Multiselect
            class="ml-2 multiselect-blue"
            v-model="local_search_options.companies.company_ids"
            ref="companies_multiselect"
            mode="tags"
            :placeholder="$t('ADVANCED_SEARCH.ALL_COMPANIES')"
            :close-on-select="false"
            :filter-results="false"
            :min-chars="0"
            :resolve-on-load="false"
            :delay="0"
            :searchable="true"
            :options="async function(query) {
              return await fetch_company_options(query);
            }"
          />

        </b-form-group>
      </b-col>

    </b-row>

    <div class="mt-8">
      <!-- Member specific -->

      <b-row>
        <b-col lg="3" sm="12">
          <!-- Member type -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.MEMBER_TYPE')">

            <b-form-select
              v-model="local_search_options.member_variables.membertype_id"
              :options="member_type_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Payment status -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.PAYMENT_STATUS')">

            <b-form-select
              v-model="local_search_options.member_variables.payment_status"
              :options="payment_status_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Sex -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.SEX')">

            <b-form-select
              v-model="local_search_options.member_variables.sex"
              :options="gender_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Public sex -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.PUBLIC_SEX')">

            <b-form-select
              v-model="local_search_options.member_variables.public_sex"
              :options="gender_options">
            </b-form-select>

          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="all_fields_shown">
        <b-col lg="3" sm="12">
          <!-- HD -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.HD')">

            <b-form-select
              v-model="local_search_options.member_variables.hd"
              :options="yes_no_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Email -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.EMAIL')">

            <b-form-select
              v-model="local_search_options.member_variables.email"
              :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Mobile -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.PHONE')">

            <b-form-select
              v-model="local_search_options.member_variables.phone"
              :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Address -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.ADDRESS')">

            <b-form-select
              v-model="local_search_options.member_variables.address"
              :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="all_fields_shown">
        <b-col lg="3" sm="12">
          <!-- zipcode -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.ZIPCODE')">

            <b-form-select
              v-model="local_search_options.member_variables.zipcode"
              :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Country -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.COUNTRY')">

            <b-form-select
              v-model="local_search_options.member_variables.country"
              :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Deactivated -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.DEACTIVATED')">

            <b-form-select
              v-model="local_search_options.member_variables.deactivated"
              :options="yes_no_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Deceased -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.DECEASED')">

            <b-form-select
              v-model="local_search_options.member_variables.deceased"
              :options="yes_no_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="all_fields_shown">
        <b-col lg="3" sm="12">
          <!-- zipcode -->
          <b-form-group
            :label="$t('MEMBER.REGION')">

            <b-form-select
              v-model="local_search_options.member_variables.region_code"
              :options="region_code_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          
          
          <!-- zipcode -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.COUNTRY_CODE')">

            <b-form-select
              v-model="local_search_options.member_variables.country_code"
              :options="country_code_options">
            </b-form-select>

          </b-form-group>

        </b-col>
        <b-col lg="3" sm="12"></b-col>
        <b-col lg="3" sm="12"></b-col>
      </b-row>


      <b-row v-if="all_fields_shown && !settings.is_faith">
        <b-col lg="3" sm="12">
          <!-- Shop item -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.SHOP_ITEM')">

            <b-form-select
              v-model="local_search_options.member_variables.shop_item"
              :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Register date -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.MISSING_MEMBERSHIP')">
            <b-row>
              <b-col cols="6">
                <memlist-date-picker
                  v-model="local_search_options.member_variables.no_mc_from"
                  :placeholder="$t('ADVANCED_SEARCH.MISSING_MEMBERSHIP_FROM')"
                  style="margin-top: 8px; max-width: 98%;"
                >
                </memlist-date-picker>
              </b-col>
              <b-col cols="6">
                <memlist-date-picker
                  v-model="local_search_options.member_variables.no_mc_to"
                  :placeholder="$t('ADVANCED_SEARCH.MISSING_MEMBERSHIP_TO')"
                  style="margin-top: 8px; max-width: 98%;"
                >
                </memlist-date-picker>
              </b-col>
            </b-row>

          </b-form-group>
        </b-col>

        <b-col lg="3" sm="12">
          <!-- Register date -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.HAVE_MEMBERSHIP')">
            <b-row>
              <b-col cols="6">
                <memlist-date-picker
                  v-model="local_search_options.member_variables.exist_mc_from"
                  :placeholder="$t('ADVANCED_SEARCH.HAVE_MEMBERSHIP_FROM')"
                  style="margin-top: 8px; max-width: 98%;"
                >
                </memlist-date-picker>
              </b-col>
              <b-col cols="6">
                <memlist-date-picker
                  v-model="local_search_options.member_variables.exist_mc_to"
                  :placeholder="$t('ADVANCED_SEARCH.HAVE_MEMBERSHIP_TO')"
                  style="margin-top: 8px; max-width: 98%;"
                >
                </memlist-date-picker>
              </b-col>
            </b-row>

          </b-form-group>
        </b-col>

        <b-col lg="3" sm="12">
          <!-- Personnr -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.PERSONNR')">

            <b-form-select
              v-model="local_search_options.member_variables.personnr"
              :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="all_fields_shown">
        <b-col lg="3" sm="12">
          <!-- Select memberships -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.MEMBERSHIPS.NAME')">

            <MembershipsSelectButton
              :search_options="local_search_options"
            />

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Select events -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.EVENTS.NAME')">

            <EventsSelectButton
              :search_options="local_search_options"
            />

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Select attribute -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.ATTRIBUTES.NAME')">

            <AttributesSelectButton
              :search_options="local_search_options"
            />

          </b-form-group>

        </b-col>
        <b-col lg="3" sm="12">
          <!-- Select educations -->
          <b-form-group
            :label="$t('ADVANCED_SEARCH.EDUCATIONS.NAME')">

            <EducationsSelectButton
              :search_options="local_search_options"
            />

          </b-form-group>
        </b-col>

      </b-row>

    </div>

    <div style="height: 54px; background-image: url(/assets/images/line.png); background-repeat-x: repeat;">
      <div class="hide-fields-button-container">
        <b-button @click="show_all(true)" v-if="!all_fields_shown" variant="outline-primary" class="vertical-center">{{ $t('ADVANCED_SEARCH.SHOW_MORE_FIELDS') }}</b-button>
        <b-button @click="show_all(false)" v-if="all_fields_shown" variant="outline-primary" class="vertical-center">{{ $t('ADVANCED_SEARCH.HIDE_MORE_FIELDS') }}</b-button>
      </div>
    </div>

    <b-row class="mt-4">
      <b-col lg="6" sm="12">
        <!--
        <div class="d-flex justify-content-left mb-4" v-if="show_save_search">
          <b-form-group
            :label="$t('ADVANCED_SEARCH.SAVE_SEARCH')">

            <b-form-input
              v-model="local_search_options.query.name"
            />

          </b-form-group>
          <b-button variant="outline-primary" style=" margin-left: 8px; max-height: 36px; margin-top: 30px;" @click="save_search">{{ $t('COMMON.SAVE') }}</b-button>
        </div>-->
      </b-col>
      <b-col lg="6" sm="12">

        <div class="d-flex justify-content-end mb-4">

          <RightSaveButton
            ref="search-button"
            :text="$t('COMMON.SEARCH')"
            @clicked="run_search()"
          />

        </div>
      </b-col>
    </b-row>

    <hr />


  </div>

</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

import AttributesSelectButton from '@/view/components/advanced_search/attributes/AttributesSelectButton.vue';
import EventsSelectButton from '@/view/components/advanced_search/events/EventsSelectButton.vue';
import MembershipsSelectButton from '@/view/components/advanced_search/memberships/MembershipsSelectButton.vue';
import EducationsSelectButton from '@/view/components/advanced_search/educations/EducationsSelectButton.vue';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'

export default {
  name: 'AdvancedSearchMemberEditor',
  components: {
    Multiselect,
    AttributesSelectButton,
    EventsSelectButton,
    MembershipsSelectButton,
    EducationsSelectButton,
    RightSaveButton
  },
  props: ['member_id','expand','show_save_search','source','search_options'],
  emits: ['on_search_result', 'on_search_count_result'],
  computed: {
    ...mapGetters(
      [
        'currentCompanyId',
        'currentPeriodId',
        'currentUserId',
        'companies',
        'periods',
        'profile',
        'settings',
        'isTHS',
        'sid'
      ]),
    country_code_options() {
      return this.$t('COUNTRIES').map((item) => ({ text: item.value, value: item.key }));
    },

    company_options() {
      if (!this.companies) {
        return [];
      }

      let comps = this.companies.map((item) => ({ text: item.shortname && item.shortname.length > 0 ? item.shortname : item.name, value: item.id }));

      comps.sort((a, b) => {
        if (a < b) {
          return -1;
        }

        return 1;
      });

      return comps;
    },
    period_options() {
      if (!this.periods) {
        return [];
      }

      const options = [{ text: this.$t('COMMON.ALL'), value: null }];

      const tmp = this.periods.map((item) => ({ text: item.name, value: item.id }));

      for (const t of tmp) {
        options.push(t);
      }

      return options;
    },

    is_mobile() {
      return is_mobile();
    },

  },
  mixins: [ toasts ],
  watch: {
    source() {
      if (this.source === 'SENDOUT') {
        this.local_search_options.is_sendout = true;
      }
    },
    search_options: {
      deep: true,
      handler(val) {

        if (this.local_search_options !== null) {
          this.local_search_options = { ...this.local_search_options }
        }
      }
    },
    companies(newVal) {
      if (newVal?.length) {
        if (this.$refs.companies_multiselect) {
          this.$refs.companies_multiselect.refreshOptions()
        }
      }
    }
  },
  mounted() {

    if (this.search_options) {
      this.local_search_options = { ...this.search_options };
    }

    this.load_regions();
    this.load_member_types();

    this.local_search_options.member_variables.period_id = this.currentPeriodId;
    this.local_search_options.source = this.source;

    if (this.local_search_options.source === 'SENDOUT') {
      this.local_search_options.is_sendout = true;
    }

    if (this.expand) {
      this.all_fields_shown = true;
    }
    else {
      this.all_fields_shown = false;
    }

    setTimeout(() => {
      if (this.$refs.companies_multiselect) {
        this.$refs.companies_multiselect.refreshOptions()
      }
    }, 500);
  },
  methods: {
    async fetch_company_options(query) {
      if (!this.companies?.length) {
        return []
      }

      let res_companies = [...this.companies];

      if (query?.length) {
        res_companies = this.companies.filter(item => item.name.toLowerCase().includes(query?.toLowerCase()));
      }

      return res_companies.map(item => ({ label: item.name, value: item.company_id }));
    },

    show_all(show) {
      this.all_fields_shown = show;
    },

    async change_per_page(per_page, page) {
      this.local_search_options.limit = per_page;
      this.local_search_options.page = page;

      this.run_search();
    },

    set_int_if_not_null(variable) {
      if (variable === null || variable === undefined) {
        return variable;
      }

      const r = parseInt(variable+'');

      if (isNaN(r)) {
        return null;
      }

      return r;
    },

    async save_search() {
      try {
        if (!this.local_search_options.query.name || this.local_search_options.query.name === '') {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.NAME_REQUIRED'));
          return;
        }

        if (this.settings.is_faith) {
          this.local_search_options.member_variables.period_id = undefined;
        }

        this.local_search_options.member_variables.age_to = this.set_int_if_not_null(this.local_search_options.member_variables.age_to);
        this.local_search_options.member_variables.age_from = this.set_int_if_not_null(this.local_search_options.member_variables.age_from);


        const res = await axios.post(`/search_query/${this.currentCompanyId}`, { name: this.local_search_options.query.name, search_options: this.local_search_options });

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ADVANCED_SEARCH.SAVED'));
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SAVE'))
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SAVE'))
      }
    },

    async run_search_count() {
      try {
        if (this.settings.is_faith) {
          this.local_search_options.member_variables.period_id = undefined;
        }

        this.local_search_options.member_variables.age_to = this.set_int_if_not_null(this.local_search_options.member_variables.age_to);
        this.local_search_options.member_variables.age_from = this.set_int_if_not_null(this.local_search_options.member_variables.age_from);

        const res = await axios.post(`/search/pagination/options/count`, { search_options: this.local_search_options });

        if (res.status === 200) {
          this.$emit('on_search_count_result', res.data.count);
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }
    },

    async run_search() {
      try {
        if (this.settings.is_faith) {
          this.local_search_options.member_variables.period_id = undefined;
        }

        this.local_search_options.member_variables.age_to = this.set_int_if_not_null(this.local_search_options.member_variables.age_to);
        this.local_search_options.member_variables.age_from = this.set_int_if_not_null(this.local_search_options.member_variables.age_from);

        const res = await axios.post(`/search/pagination/options`, { search_options: this.local_search_options });

        this.$refs['search-button'].stop();

        this.run_search_count();

        if (res.status === 200) {
          this.$emit('on_search_result', res.data, this.local_search_options);
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }

      this.$refs['search-button'].stop();
    },

    async load_member_types() {
      try {
        const res = await axios.get('/membertype')

        this.member_type_options = [{ value: null, text: this.$t('COMMON.ALL') }];

        if (res.status === 200) {
          for (const mt of res.data) {
            this.member_type_options.push({ value: mt.id, text: mt.name });
          }
        }

      }
      catch (err) {
        console.error('membertype ', err);
      }

    },


    async load_regions() {

      try {
        const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes');

        const regions = res.data.regions;
        this.region_code_options = [];

        for (var k in regions) {
          this.region_code_options.push({ value: k, text: regions[k].region });
        }

        this.region_code_options.sort(function(a, b) {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.UNABLE_LIST_REGIONS'));
      }
    },
  },
  data() {
    return {

      region_code_options: [],

      member_type_options: [],

      all_fields_shown: false,

      gender_options: [
        { text: this.$t('ADVANCED_SEARCH.ALL'), value: null },
        { text: this.$t('COMMON.MAN'), value: 'M' },
        { text: this.$t('COMMON.WOMAN'), value: 'F' },
        { text: this.$t('COMMON.OTHER'), value: 'O' },
        { text: this.$t('COMMON.UNKNOWN'), value: 'U' },
      ],

      exists_missing_all_options: [
        { value: null, text: this.$t('ADVANCED_SEARCH.ALL') },
        { value: 'EXISTS', text: this.$t('ADVANCED_SEARCH.EXISTS') },
        { value: 'NOT_EXISTS', text: this.$t('ADVANCED_SEARCH.MISSING') }
      ],

      yes_no_all_options: [
        { value: null, text: this.$t('ADVANCED_SEARCH.ALL') },
        { value: 1, text: this.$t('ADVANCED_SEARCH.YES') },
        { value: 0, text: this.$t('ADVANCED_SEARCH.NO') }
      ],

      payment_status_options: [
        { value: null, text: this.$t('ADVANCED_SEARCH.ALL') },
        { value: 1, text: this.$t('ADVANCED_SEARCH.PAID') },
        { value: 0, text: this.$t('ADVANCED_SEARCH.NOT_PAID') }
      ],

      local_search_options: {

        source: 'MEMBER', // MEMBER, SENDOUT, RENEW
        entity_type: 'MEMBER', // MEMBER, USER, PERSON
        is_sendout: false,

        // list of values
        properties: {
          include: 'ANY', // ANY/ALL
          prop_ids: [], // array of ids
        },

        memberships: {
          include: 'ANY', // ANY/ALL
          shop_item_ids: [] // array of ids
        },

        events: {
          include: 'ANY', // ANY/ALL
          event_ids: [], // array of ids
        },

        educations: {
          include: 'ANY',
          education_ids: [],
        },

        companies: {
          include: 'ANY',
          company_ids: [], // array of company ids
        },

        page: 1,
        limit: 100,
        order_by: {
          public_id: 'desc'
        },

        query: {
          id: null,
          name: null
        },

        member_variables: {
          // simple values
          skv_period_id: null,
          period_id: null, // int id
          membertype_id: null, // id

          no_mc_from: null, // yyyy-mm-dd
          no_mc_to: null, // yyyy-mm-dd
          exist_mc_from: null, // yyyy-mm-dd
          exist_mc_to: null, // yyyy-mm-dd
          rdt_from: null, // yyyy-mm-dd
          rdt_to: null, // yyyy-mm-dd
          age_from: null, // 0 - 100
          age_to: null, // 0 - 100
          region_code: null, // string

          sex: null, // M, F, U, O
          public_sex: null, // M, F, U, O

          email: null, // EXISTS, NOT_EXISTS
          phone: null, // EXISTS, NOT_EXISTS
          address: null, // EXISTS, NOT_EXISTS
          zipcode: null, // EXISTS, NOT_EXISTS
          country: null, // EXISTS, NOT_EXISTS
          country_code: null, // string
          shop_item: null, // EXISTS, NOT_EXISTS
          personnr: null, // EXISTS, NOT_EXISTS


          deactivated: null, // 1, 0
          deceased: null, // 1, 0
          hd: null, // 1, 0
          payment_status: null, // 1, 0
        },


      },

      membertypes: [],

    }
  },
}

</script>

<style lang="css" scoped>

.multiselect-blue {
  --ms-tag-bg: #DBEAFE;
  --ms-tag-color: #2563EB;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 9px;
  display:block;
}

.hide-fields-button-container {
  height: 54px;
  position: relative;
  width: 216px;
  margin: auto;
  background-color: white;
}


.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  padding: 4px !important;
}

.row {
  margin-left: 0px !important;
}

.col-form-label {
  margin-top: 6px !important;
}

.page-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.fill-rest {
  flex: 1 1 auto;
}


.quick-search-card {
  overflow: scroll;
}

.selected-item-active {
  border-radius: 4px;
  background: #f4fcff;
  color: black;
}

.card-title {
  background-color:#f7f7f7;
  border-bottom:1px solid #dedede;
  padding-bottom: 15px;

}

.card-title-p {
  margin-bottom: 8px !important;
  margin-top: 7px !important;
}


.top-container {
  display: flex;
}

.content {
  flex-grow: 1;
  margin-top: 0px !important;
  padding-top: 0px !important;
  margin-left: 8px !important;
}



.drawer-header {
  margin-bottom: -13px !important;
}

.drawer {
  border-right: 1px solid #dddddd !important;
}

.fixed-font {
  font-size: 0.95rem !important;
  font-weight: 500;
  line-height: 1rem;
}

:deep .v-application--wrap {
  min-height: 0px !important;
}

.fix_margin {
  height: 38px;
  margin: 0px !important;
  padding: 0px !important;
}


:deep .mx-datepicker {
  width: 100%;
}
</style>
